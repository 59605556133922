/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { useLocation } from "@reach/router"
import { useIntl } from "gatsby-plugin-intl"

import ogimage from "../assets/images/og-image.jpg"
import { useReverseLanguage } from "./hooks/useReverseLanguage"

function SEO({ description, title, image, themeColor, noindex }) {
  const location = useLocation()
  const { locale } = useIntl()
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            image
          }
        }
      }
    `
  )

  let languages = ["en", "de", "ca", "it", "da", "pl", "sv", "es", "fr", "fi", "no", "hu", "nl", "sk"]
  const metaDescription = description || site.siteMetadata.description
  const lang = locale
  const path = location ? location.pathname.split("/" + lang)[1] : ""
  const reversed = useReverseLanguage()

  return (
    <Helmet
      htmlAttributes={{
        lang,
        dir: reversed ? "rtl" : "ltr",
      }}
      title={title}
      titleTemplate={`%s`}
    >
      {/* <!-- BEGIN Usercentrics related code --> */}
      <link rel="preconnect" href="//app.usercentrics.eu" />
      <link rel="preconnect" href="//api.usercentrics.eu" />
      <link rel="preconnect" href="//privacy-proxy.usercentrics.eu" />
      <link rel="preload" href="//app.usercentrics.eu/browser-ui/latest/loader.js" as="script" />
      <link rel="preload" href="//privacy-proxy.usercentrics.eu/latest/uc-block.bundle.js" as="script" />
      <script id="usercentrics-cmp" data-settings-id="YXWrnanuq" src="https://app.usercentrics.eu/browser-ui/latest/loader.js" async></script>
      <script type="application/javascript" src="https://privacy-proxy.usercentrics.eu/latest/uc-block.bundle.js"></script>

      {/* Force page to not be indexed */}
      {noindex && <meta name="robots" content="noindex" />}

      {/* Meta tags & Languages */}
      <meta name="theme-color" content={themeColor} />
      <meta name="description" content={metaDescription} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content={image} />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content={site.siteMetadata.author} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={image} />
      <meta name="google-site-verification" content="" />
      <link rel="alternate" href={location ? location.origin + "/" + "en" + path : ""} hrefLang="x-default" key={"x-default"} />
      <link rel="alternate" href={location ? location.origin + "/" + languages[0] + path : ""} hrefLang={languages[0]} key={languages[0]} />
      <link rel="alternate" href={location ? location.origin + "/" + languages[1] + path : ""} hrefLang={languages[1]} key={languages[1]} />
      <link rel="alternate" href={location ? location.origin + "/" + languages[2] + path : ""} hrefLang={languages[2]} key={languages[2]} />
      <link rel="alternate" href={location ? location.origin + "/" + languages[3] + path : ""} hrefLang={languages[3]} key={languages[3]} />
      <link rel="alternate" href={location ? location.origin + "/" + languages[4] + path : ""} hrefLang={languages[4]} key={languages[4]} />
      <link rel="alternate" href={location ? location.origin + "/" + languages[5] + path : ""} hrefLang={languages[5]} key={languages[5]} />
      <link rel="alternate" href={location ? location.origin + "/" + languages[6] + path : ""} hrefLang={languages[6]} key={languages[6]} />
      <link rel="alternate" href={location ? location.origin + "/" + languages[7] + path : ""} hrefLang={languages[7]} key={languages[7]} />
      <link rel="alternate" href={location ? location.origin + "/" + languages[8] + path : ""} hrefLang={languages[8]} key={languages[8]} />
      <link rel="alternate" href={location ? location.origin + "/" + languages[9] + path : ""} hrefLang={languages[9]} key={languages[9]} />
      <link rel="alternate" href={location ? location.origin + "/" + languages[10] + path : ""} hrefLang={languages[10]} key={languages[10]} />
      <link rel="alternate" href={location ? location.origin + "/" + languages[11] + path : ""} hrefLang={languages[11]} key={languages[11]} />
      <link rel="alternate" href={location ? location.origin + "/" + languages[12] + path : ""} hrefLang={languages[12]} key={languages[12]} />
      <link rel="alternate" href={location ? location.origin + "/" + languages[13] + path : ""} hrefLang={languages[13]} key={languages[13]} />
    </Helmet>
  )
}

SEO.defaultProps = {
  meta: [],
  description: ``,
  image: ogimage,
  themeColor: "#FFFAF6",
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  image: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  location: PropTypes.any,
  themeColor: PropTypes.string,
}

export default SEO
